@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .scrollBar {
    @apply [&::-webkit-scrollbar]:w-1.5 [&::-webkit-scrollbar]:h-1.5 [&::-webkit-scrollbar-track]:bg-none [&::-webkit-scrollbar-thumb]:bg-[#D9E5F2] [&::-webkit-scrollbar-thumb]:rounded-xl;
  }
}

input[type="checkbox"] {
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: white;
  border: 2px solid #6D7079;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type="checkbox"]:checked {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none"><path d="M10.8125 1.375L4.3958 7.5L1.1875 4.4375" stroke="%236D7079" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-size: 13px 13px;
  background-repeat: no-repeat;
  background-position: center;
}

 /* Hide the spin buttons in WebKit browsers */
 input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
 }

 /* Hide spin buttons in Firefox */
 input[type="number"] {
   -moz-appearance: textfield;
   appearance: textfield;
 }

@layer base {
  :root {
    /* Typography Scale */
    --font-heading-1: 2.5rem;
    /* 40px */
    --font-heading-2: 2rem;
    /* 32px */
    --font-heading-3: 1.75rem;
    /* 28px */
    --font-heading-4: 1.5rem;
    /* 24px */
    --font-heading-5: 1.25rem;
    /* 20px */
    --font-paragraph: 1rem;
    /* 16px */
    --font-small: 0.875rem;
    /* 14px */
    --font-xs: 0.75rem;
    /* 12px */

    /* Brand Colors - Primary Blues */
    --primary-50: 217 100% 98%;
    --primary-100: 217 100% 95%;
    --primary-200: 217 100% 90%;
    --primary-300: 217 100% 80%;
    --primary-400: 217 100% 70%;
    --primary-500: 217 100% 60%;
    /* Base Primary */
    --primary-600: 217 100% 50%;
    --primary-700: 217 100% 40%;
    --primary-800: 217 100% 30%;
    --primary-900: 217 100% 20%;
    --primary-1000: 220 81% 36% /1;
    --primary-1100: 208 13% 71% /1;

    /* Neutral Colors - From your design */
    --neutral-50: 220 20% 98%;
    --neutral-100: 220 20% 95%;
    --neutral-200: 220 20% 90%;
    --neutral-300: 220 20% 80%;
    --neutral-400: 220 20% 70%;
    --neutral-500: 220 20% 60%;
    --neutral-600: 220 20% 50%;
    --neutral-700: 220 20% 40%;
    --neutral-800: 220 20% 30%;
    --neutral-900: 220 20% 20%;

    /* Status Colors - From your design */
    --status-success: 142 76% 36%;
    --status-warning: 48 96% 53%;
    --status-error: 0 84% 60%;
    --status-info: 217 91% 60%;

    /* Medical Status Colors */
    --status-All-studies: 222 5% 85% / 0.4;
    --status-scheduled: 205 100% 85% / 0.3;
    --status-preliminary: 51 58% 60% / 0.2;
    --status-in-review: 266 100% 97% / 0.75;
    --status-revised: 27 51% 57% / 0.2;
    --status-completed: 139 39% 54% / 0.15;
    /*status Border Colors */ 
    --border-All-studies:240 1% 38% / 1;
    --border-scheduled: 219 82% 44% / 1;
    --border-preliminary: 33 86% 29% / 1;
    --border-in-review: 269 81% 25% / 1;
    --border-revised: 28 89% 14% / 1;
    --border-completed: 155 79% 16% / 1;

    /* System Colors */
    --background: 0 0% 100% / 1;
    --foreground: 217 33% 17%;
    --card: 0 0% 100%;
    --card-foreground: 217 33% 17%;
    --popover: 0 0% 100%;
    --popover-foreground: 217 33% 17%;
    --border: 208 13% 71% / 1;
    --input: 217 33% 93%;
    --ring: var(--primary-500);
    --surface: 219 82% 50% / 0.1;
    --card-active: 219 82% 44% / 0.2;
    --card-static: 0 0% 100% / 1;
    --card-filled: 219 82% 44%;
    --card-unfilled: 210 5% 24% / 1;
    --header-background: 0 0% 100% /1;
    --header-title: 210 5% 24% /1;
    --header-patientName-key: 208 13% 71% /1;
    --header-patientName-value: 225 7% 33% /1;
    --header-border-line: 211 49% 90% /1;
    --header-profile-border: 0 0% 100% /1;
    --header-icons: 222 5% 38% /1;
    --secondary-icons: var(--primary-1000);
    --header-cart-icon: 208 13% 71% /1;
    --header-profile: 311 16% 53% /1;
    --header-profile-container: 220 6% 10% /1;
    --header-tabs-static: 220 60% 97% / 1;
    --header-tabs-active: 219 82% 44% / 0.2;

    --inner-background: 219 82% 44% / 0.05;
    --light-text: 222 5% 38%;
    --dark-text: 210 5% 24% / 1;
    
    --icon-bg: 210 7% 94% / 1;
    --icon-active-bg: 219 65% 89% / 1;
    --icon-active-border: 219 82% 44% / 1;

    --image-label-bg: 219 82% 44% / 0.6;
    --typehover: 220 77% 56% / 0.1;
    --collaborators-icon: 53 57% 92% / 1;
    --collaborators-background: 47 72% 71% / 0.3;
    --type-hover-text: 219 82% 44% / 1;
    --dropdown-primary-text: 222 5% 38% / 1;
    --dropdown-secondary-text: 225 7% 33% / 1;
    --dropdown-background: 0 0% 100% / 1;
    --dropdown-tag-border: 33 86% 29% / 1;
    --dropdown-tag-bg: 47 72% 71% / 0.1;
    --dropdown-tag-text: 30 77% 26% / 1;
    --dropdown-close-icon: 0 0% 0% / 1;
    --dropdown-selection-bg: 210 17% 98% / 1;
    --dropdown-selection-arrow: 210 7% 94% / 1;
    --dropdown-selection: 212 52% 95% / 1;
    --dropdown-border: 210 7% 94% / 1;
    --table-header: 219 82% 44% 0.2;

    
    --notification-button: 219 82% 44% / 1;
    --notification-light-text: 217 27% 19% / 1;
    --userText:225 5% 10% / 1;
    --user-dropText:225 5% 15% / 1;
    --user-drop-background: 210 17% 98% / 1;
    --background-blur: 222 5% 50% / 1;
    --card-background: 0 0% 100% / 1;
    --button:219 82% 44% / 1;
    --switch-tab-background: 220 100% 75% / 0.12;
    --switch-tab-hover:219 82% 44% / 1;
    --switch-tab-text:225 6% 14% / 1;
    --user-info:210 17% 98% / 1;

    --tab-background: 220 60% 97% / 1;
    --icon-background: 311 16% 53% / 1;
    --edit-button: 219 82% 44%/ 1;
    --input-border: 225 5% 85% / 1;
    --text-color: 223 6% 25% / 1;
    --card-label:222 5% 38% / 1;
    --icon-text:0 0% 100% / 1;

    --builder-background: 0 0% 100% / 1;
    --builder-light-text: 222 5% 38%;
    --builder-dark-text: 210 5% 24% / 1;
    --builder-icon-bg: 210 7% 94% / 1;
    --builder-label-bg: 219 82% 44% / 0.6;
    --builder-selected-border: 219 82% 44%;
    --builder-hover-border: 219 82% 44%;
    --builder-default-border: var(--border);
    --builder-selected-background: 219 82% 44% / 0.2;
    /* Border Radius */
    --radius-sm: 0.25rem;
    /* 4px */
    --radius-md: 0.375rem;
    /* 6px */
    --radius-lg: 0.5rem;
    /* 8px */
    --radius-xl: 0.75rem;
    /* 12px */

    /* Light theme shadows */
    --shadow-anatomy-light: 0 3px 8px 0 hsla(0, 0%, 0%, 0.07);

    /* Other light theme variables */
    --anotomy-type-hover: 210 97% 98%;
    --anotomy-type-hover-text: 220 6% 10%;

    /* Light theme colors */
    --anatomy-btn-bg: 0 0% 100%;
    /* White */
    --anatomy-btn-hover: 210 100% 98%;
    /* Light blue hover */
    --anatomy-btn-text: 222 47% 11%;
    /* Dark text */
    --anatomy-btn-shadow: 0 0% 0%;
    /* Shadow color */
    --anatomy-panel-bg: 0 0% 100%;
    /* Panel background */

    /* Anatomy Button Shadows */
    --anatomy-shadow-opacity: 0.07;
    --anatomy-shadow-y: 3px;
    --anatomy-shadow-blur: 8px;

    /* Radio Button Colors */
    --radio-outer-checked-bg: 210 17% 98% / 1;
    --radio-outer-checked-border: 0 0% 0% / 0.25;
    --radio-outer-unchecked-bg: 210 17% 98% / 1;
    --radio-outer-unchecked-border: 0 0% 0% / 0.25;
    --radio-inner-checked-bg: 219 82% 42% / 1;     /*blue-500*/
    --radio-inner-checked-border: 0 0% 0% / 0.25;
    --display-primary-text: 225 25% 15% / 1;
    --display-secondary-text: 225 5% 45% / 1;
    --display-border: 223 6% 75% / 1;

    --toggle-enabled-background: 0 0% 100% / 1;
    --toggle-disabled-background: 210 7% 94% / 1;
    --tools-color: 225 7% 33% / 1;
    --tools-background: 219 82% 44% /0.2;
    --tools-active: 219 82% 44% / 1;

    --resolve-button: 220 77% 56% / 1;

    --chat-background: 214 100% 97% / 1;
    --chat-border: 220 13% 91% / 1;
    --chat-with-tag-background: 210 17% 98% / 1;

    --unresolved-bg: 210 17% 98% / 1;
    --unresolved-border: 211 49% 90% / 1;
    --unresolved-hover-bg: 220 100% 75% / 0.12;
    --unresolved-hover-border: 219 82% 44% / 1;

    --resolved-bg: 240 4% 95% / 0.75;
    --resolved-border: 211 49% 90% / 1;
    --resolved-hover-bg: 220 100% 75% / 0.12;
    --resolved-hover-border: 219 82% 44% / 1;

    --comment-tag-background: 220 100% 89% / 1;
    --comment-tag-border: 220 13% 91% / 1;
    --comment-tag-text: 219 82% 44% / 1;

    --tag-background: 210 17% 98% / 1;
    --tag-border: 220 13% 91% / 1;
  
    --send-input-background: 210 17% 98% / 1;
    --send-input-border: 210 7% 94% / 1;

    --slider-bg: 240 4% 95% / 1;

    /* Fullscreen Viewer Colors */
    --viewer-background: 220 6% 10% / 1;
    --viewer-header: 0 0% 13% / 1;
    --viewer-nav-button: 0 0% 100% / 0.1;
    
    --display-title: 225 5% 15% / 1;
    --settings-bg: 0 0% 100% / 1;
    --filter-hover-bg: 219 82% 44% / 0.2;
    --trash-bg: 0 96% 56% / 0.1;
    --user-card-border:220 84% 95% / 1

    --bookmark-input-bg: 210 17% 98% / 1;
    --bookmark-add-button: 219 82% 44% / 1;
    --bookmark-input-border: 210 7% 94% / 1;
    --bookmark-cancel-border: 225 5% 15% / 1;
    --bookmark-success-bg: 150 59% 47% / 0.07;
    --bookmark-success-tick: 150 78% 25% / 1;

  }

  .dark {
    --background: 220 6% 10% / 1;
    --foreground: 0 0% 100%;
    --card: 217 33% 17%;
    --card-foreground: 0 0% 100%;
    --popover: 217 33% 17%;
    --popover-foreground: 0 0% 100%;
    --border: 220 5% 32% / 1;
    --input: 217 33% 25%;
    --surface: 217 33% 17%;
    --card-active: 220 77% 56% / 0.2;
    --card-static: 219 82% 44% / 0.2;
    --card-filled: 220 100% 75% / 1;
    --card-unfilled: 208 13% 71% / 1;
    --header-background: 220 6% 10% /1;
    --header-title: 208 13% 71% /1;
    --header-patientName-key: 195 1% 28% /1;
    --header-patientName-value: 222 4% 48% /1;
    --header-border-line: 210 5% 20% /1;
    --header-profile-border: 210 5% 20% / 1;
    --header-icons: 222 5% 38% /1;
    --secondary-icons: var(--primary-1100);
    --header-cart: 95 1% 28% /1;
    --header-profile: 311 16% 53% /1;
    --input: 217 33% 25%;
    --inner-background: 0 0% 12% / 1;
    --light-text: 222 4% 48%;
    --dark-text: 208 13% 71% /1;
    --icon-bg: 204 5% 20% / 1;
    --image-label-bg: 219 82% 44% / 0.6;
    --builder-background: 0 0% 12%;
    --builder-light-text: 222 4% 48%;
    --builder-dark-text: 208 13% 71% /1;
    --builder-icon-bg: 204 5% 20% / 1;
    --builder-label-bg: 219 82% 44% / 0.6;
    --typehover: 220 77% 56% / 0.1;
    --collaborators-icon: 57 100% 95% 0.75;
    --collaborators-background: 47 72% 71% / 0.1;
    --type-hover-text: 220 77% 56% / 1;
    --typecontainer: 0 0% 0% / 0.07;
    --dropdown-text: 210 5% 20% / 1;
    --dropdown-primary-text: 210 8% 59% / 1;
    --dropdown-secondary-text: 208 13% 71% / 1;
    --dropdown-background: 210 5% 24% / 1;
    --dropdown-selection-bg: 0 0% 12% / 1;
    --dropdown-selection-arrow: 225 6% 14% / 1;
    --dropdown-tag-border: 57 100% 95% / 1;
    --dropdown-tag-bg: 57 100% 95% / 1;
    --dropdown-tag-text: 30 50% 74% / 1;
    --dropdown-close-icon: 0 0% 100% / 1;
    --dropdown-selection: 212 52% 95% / 1;
    --dropdown-border: 25 6% 14% / 1;

    --header-tabs-static: 0 0% 12% / 1;
    --header-tabs-active: 220 37% 16% / 1;
    --notification-button: 220 100% 75% / 1;
    --notification-light-text: 213 48% 90% / 1;
    --typecontainer:0 0% 0% / 0.07;
    --table-header: 219 82% 44% 0.2;
    --user-drop-background: 0 0% 12% / 1;
    --userText:210 7% 94% / 1;
    --user-dropText:208 13% 71% / 1;
    --background-blur:220 6% 10% / 1;
    --card-background:220 6% 10% / 1;
    --button:220 81% 36% / 1;
    --switch-tab-background: 225 6% 16% / 1;
    --switch-tab-hover:208 13% 71% / 1;
    --switch-tab-text:211 49% 90% / 1;
    --user-info:0 0% 12% / 1;
    --user-card-border:210 5% 24% / 1;

    --tab-background: 0 0% 12% / 1;
    --icon-background: 311 16% 53% / 1;
    --edit-button: 219 82% 44%/ 1;
    --input-border: 210 5% 24% / 1;
    --text-color:210 7% 94% / 1;
    --card-label: 208 13% 71% / 1;
    --icon-text:220 6% 10% / 1;
    --user-dropBackground: hsla(220, 6%, 10%, 1);

    /* Dark theme shadows */
    --shadow-anatomy-dark: 0 3px 8px 0 hsla(0, 0%, 0%, 0.15);
    --shadow-secondary: 4px 4px 8px 0px hsla(220, 81%, 36%, 0.26) inset;

    /* Other dark theme variables */
    --anotomy-type-hover: 217 33% 17%;
    --anotomy-type-hover-text: 0 0% 100%;

    /* Dark theme colors */
    --anatomy-btn-bg: 215 28% 17%;
    /* #1F2937 */
    --anatomy-btn-hover: 217 33% 17%;
    /* #101827 */
    --anatomy-btn-text: 0 0% 100%;
    /* White */
    --anatomy-btn-shadow: 0 0% 0%;
    /* Shadow color */
    --anatomy-panel-bg: 222 47% 11%;
    /* Panel background */

    /* Anatomy Button Shadows */
    --anatomy-shadow-opacity: 0.15;
    --builder-selected-border: 220 100% 75%;
    --builder-hover-border: 220 100% 75%;
    --builder-default-border: var(--border);

    /* Medical Status Colors */
    --status-All-studies: 225 6% 13% / 0.4;
    --status-scheduled: 221 27% 18% / 0.3;
    --status-in-review: 260 20% 21% / 0.75;
    --status-revised: 27 51% 57% / 0.2; 

    /*status Border Colors */
    --border-All-studies: 208 13% 71% / 1;
    --border-scheduled: 205 89% 93% / 1;
    --border-preliminary: 57 100% 95% / 1;
    --border-in-review: 268 100% 95% / 1;
    --border-revised: 42 100% 95% / 1;
    --border-completed: 150 77% 95% / 1;
    
    /* Radio Button Colors */
    --radio-outer-checked-bg: 210 17% 98% / 1;
    --radio-outer-checked-border: 210 7% 94% / 1;
    --radio-outer-unchecked-bg: 210 17% 98% / 1;
    --radio-outer-unchecked-border: 210 7% 94% / 1;
    --radio-inner-checked-bg: 219 82% 42% / 1;     /*blue-500*/
    --radio-inner-checked-border: 0 0% 0% / 0.25;
    --display-primary-text: 0 0% 59% / 1;
    --display-secondary-text: 225 5% 45% / 1;
    --display-border: 223 6% 75% / 1;
    
    --toggle-enabled-background: 220 100% 75% / 1;
    --toggle-disabled-background: 225 6% 14% / 1;
    --tools-color: 208 13% 71% / 1;
    --tools-background: 220 81% 36% / 0.26;
    --tools-active: 220 100% 75% / 1;

    --icon-active-bg: 220 37% 16% / 1;
    --icon-active-border: 220 81% 25% / 1;

    --resolve-button: 220 77% 56% / 1;

    --chat-background: 0 0% 12% / 1;
    --chat-border: 219 6% 14% / 1;
    --chat-with-tag-background: 0 0% 12% / 1;

    --send-input-background: 0 0% 12% / 1;
    --send-input-border: 210 5% 24% / 1;

    --unresolved-bg: 225 6% 14% / 1;
    --unresolved-border: 220 100% 75% / 0.12;
    --unresolved-hover-bg: 220 100% 75% / 0.12;
    --unresolved-hover-border: 219 82% 44% / 1;

    --resolved-bg: 0 0% 12% / 1;
    --resolved-border: 220 100% 75% / 0.12;
    --resolved-hover-bg: 220 100% 75% / 0.12;
    --resolved-hover-border: 219 82% 44% / 1;

    --comment-tag-background: 225 7% 33% / 0.6;
    --comment-tag-border: 225 7% 33% / 0.6;
    --comment-tag-text: 210 7% 94% / 1;

    --tag-background: 210 17% 98% / 1;
    --tag-border: 220 13% 91% / 1;

    /* Slider Colors */
    --slider-bg: 210 5% 24% / 1;

    --display-title: 210 7% 94% / 1;
    --general-primary-text: 208 13% 71% / 1;
    --settings-bg: 220 6% 10% / 1;
    --filter-hover-bg: 220 37% 16% / 1;

    --bookmark-input-bg: 225 6% 14% / 1;
    --bookmark-add-button: 220 81% 36% / 1;
    --bookmark-input-border: 225 6% 14% / 1;
    --bookmark-cancel-border: 208 13% 71% / 1;
    --bookmark-success-bg: 152 69% 64% / 0.15;
    --bookmark-success-tick: 150 78% 25% / 1;
    
  }
}

/* Font Faces */
@layer base {
  @font-face {
    font-family: 'Roboto';
    src: url('../../public/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Roboto';
    src: url('../../public/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Roboto';
    src: url('../../public/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  /* Base Styles */
  body {
    @apply bg-background text-foreground;
    font-family: 'Roboto', sans-serif;
    font-feature-settings: "rlig" 1, "calt" 1;
  }

  .scrollbar-hidden {
    scrollbar-width: none; /* For Firefox */
  }
  
  .scrollbar-hidden::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }

  /* Typography Classes */
  .h1 {
    @apply text-[var(--font-heading-1)] font-bold leading-tight;
  }

  .h2 {
    @apply text-[var(--font-heading-2)] font-bold leading-tight;
  }

  .h3 {
    @apply text-[var(--font-heading-3)] font-bold leading-tight;
  }

  .h4 {
    @apply text-[var(--font-heading-4)] font-medium leading-snug;
  }

  .h5 {
    @apply text-[var(--font-heading-5)] font-medium leading-snug;
  }

  .body {
    @apply text-[var(--font-paragraph)] leading-normal;
  }

  .small {
    @apply text-[var(--font-small)] leading-normal;
  }

  .xs {
    @apply text-[var(--font-xs)] leading-normal;
  }
}

@layer base {
  * {
    @apply border-border outline-ring/50;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.custom-pointer {
  @apply cursor-pointer;
}

.cursor-vector {
  cursor: url('/vector.png') 16 16, auto;
}

:root {
  --custom-cursor: url('/vector.png') 16 16, auto !important;
}